.custom-width-button {
  width: 3rem !important
}

.loader {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* Adjust as necessary */
}
