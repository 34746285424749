.main-header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;

  .p-avatar {
    height: 38px;
    width: 38px;
  }

  .avatar-name {
    font-family: var(--font-manrope);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: var(--black-pearl);
  }
}
