.main-sidebar {
    border-right: 1px solid #d4d7e3;
    width: 173px;
  
    .sidebar-logo {
      padding: 15px 16px;
      min-height: 60px;
    }
  
    .menu-items-list {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 12px;
    }
  
    .footer-menu .p-menu ul li {
      margin: 5px 0;
    }
  
    .p-menu {
      ul {
        li {
          margin-bottom: 10px;
          &.p-focus {
            a {
              color: #fff;
              background: var(--torch-red);
              span {
                color: #fff;
              }
            }
          }
  
          a {
            border-radius: 6px;
            padding: 10px 7px;
            min-width: 130px;
            height: 40px;
  
            span {
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              color: var(--black-pearl);
            }
  
            &:hover {
              color: #fff;
              background: var(--torch-red);
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  