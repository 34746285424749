@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

body {
  font-family: var(--font-family);
  background-color: #f8f7fa;
  margin: 0;
}

:root {
  --black-pearl: #0c1421;
  --torch-red: #f12336;
  --rhino: #313957;
  --border-primary: #d4d7e3;
  --santas-gray: #959cb6;

  --btn-shadow: 0px 5px 10px 0px #f1233633;

  --font-family: "Poppins";
  --font-manrope: "Manrope";
  --font-public-sans: "Public Sans", sans-serif;
}

.border-primary-color {
  border-color: var(--border-primary);
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

h1 {
  // font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--black-pearl);
}

h2 {
  // font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--black-pearl);
}

h3 {
  // font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: var(--black-pearl);
}

a {
  color: var(--torch-red);
  text-decoration: none;
}

p {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
}

button {
  .p-button-label {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.434px;
  }

  &.p-button {
    padding: 8.5px 20px;

    &.p-button-link {
      color: var(--torch-red);
    }

    &.p-button-icon-only {
      width: auto;
    }
  }

  &.primary {
    background-color: var(--torch-red);
    border-color: var(--torch-red);

    &:hover {
      background-color: #f123361a;

      span {
        color: var(--torch-red);
      }
    }
  }

  &:focus {
    box-shadow: none;
  }

  &.p-button-outlined {
    &.p-button-danger {
      background-color: #f123361a;
      border-color: var(--torch-red);

      &:hover {
        background-color: var(--torch-red);

        span {
          color: #ffffff;
        }
      }
    }

    &.p-button-secondary {
      border-color: #31395780;
      color: #31395780;
      background-color: transparent;
    }
  }

  &.p-button-secondary {
    background-color: var(--rhino);
  }
}

.form-group {
  margin-bottom: 20px;
  position: relative;

  .p-inline-message {
    &-error {
      background-color: transparent;
      padding: 0;
      // position: absolute;
      // left: 0;
      // bottom: -20px;

      svg {
        display: none;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    &-success {
      .p-inline-message-text {
        font-size: 12px;
      }
    }
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--black-pearl);
    font-family: var(--font-family);

    small {
      color: var(--santas-gray);
    }

    span {
      &.required-field {
        color: var(--torch-red);
      }
    }
  }

  input {
    border-color: #d4d7e3;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    min-height: 40px;
    color: var(--rhino);

    &:focus {
      box-shadow: none;
    }

    &:hover {
      border: 1px solid var(--rhino);
    }

    &.form-control {
      width: 100% !important;
    }
  }

  ::placeholder,
  .p-placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--santas-gray);
  }

  textarea {
    &:focus {
      box-shadow: none;
      border: 1px solid var(--rhino);
    }

    &:hover {
      box-shadow: none;
      border: 1px solid var(--rhino);
    }
  }

  .p-dropdown {
    &:hover {
      border-color: var(--rhino);
    }

    &:focus {
      box-shadow: none;
      border: 1px solid var(--rhino);
    }
  }

  button {
    &.search-loading {
      position: absolute;
      right: 15px;
      height: 100%;
      z-index: 1;

      .pi-spinner {
        &::before {
          color: var(--santas-gray);
        }
      }
    }
  }
}

.p-input-icon {
  svg:focus {
    outline: none;
  }
}

.p-dropdown {
  &.p-focus {
    box-shadow: none;
    border-color: var(--rhino);
  }

  .p-dropdown-label {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.form-control {
  &:focus {
    box-shadow: none;
    border: 1px solid var(--rhino);
    border-radius: 6px;
  }

  &.p-password {
    display: contents;
  }

  &.p-invalid,
  &.p-invalid input {
    border-color: #e24c4c;
  }
}

.copy-right {
  position: absolute;
  bottom: 15px;

  p {
    color: var(--santas-gray);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
  }
}

.auth-details {
  .login-width {
    width: 386px;

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
    }

    button {
      &.primary {
        box-shadow: var(--btn-shadow);
        margin-top: 3px;
      }
    }

    .account-details p {
      font-size: 14px;
      // font-weight: 400;
      line-height: 25.6px;
      color: #122b31;
    }
  }
}

.login-left {
  flex: 1;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container {
  max-width: 100%;
  width: 670px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .character-image {
    max-width: 100%;
    /* width: 100%; */
    height: 100%;
    object-fit: contain;
  }
}

.dashboar-wrap {
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 81px);
}

.p-breadcrumb {
  background-color: transparent;
  margin-top: 4px;

  ol {
    li {
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;

      svg.p-icon.p-breadcrumb-chevron {
        width: 10px;
        height: 10px;
      }

      .p-menuitem-text {
        color: var(--black-pearl);
      }

      &:first-child {
        .p-menuitem-text {
          color: #0c142180;
        }
      }
    }
  }
}

.project-content {
  .p-tabview-panels {
    height: calc(100vh - 137px);
    overflow-y: auto;
  }
}

.p-card {
  box-shadow: 0px 4px 18px 0px #4b465c1a;
}

.card-items {
  // gap: 30px;
  // display: grid;
  // grid-template-columns: auto auto auto;

  .p-chip-icon {
    &:before {
      font-size: 24px;
      color: var(--santas-gray);
    }
  }

  .p-chip-text {
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    color: var(--black-pearl);
    margin: 0;
  }

  .p-card .p-card-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: var(--black-pearl);
    margin-bottom: 5px;
  }

  .p-card .p-card-subtitle {
    color: #4b465c;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .p-card-content {
    padding-bottom: 0;
    padding-top: 14px;
  }

  footer {
    margin-top: 10px;
    padding-top: 15px;

    .p-button-danger {
      &:active {
        background-color: transparent;
      }
    }
  }

  .icon-group {
    button {
      margin-right: 20px;

      .pi {
        &::before {
          color: var(--santas-gray);
        }
      }

      .p-button-label {
        color: var(--santas-gray);
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}

.p-tabview-panels {
  background-color: transparent;
}

.p-dialog {
  .p-dialog-header {
    padding: 20px 20px 40px 20px;

    .p-dialog-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #021e35;
      font-family: "Public Sans", sans-serif;
    }

    .p-dialog-header-close {
      background-color: #021e3533;
      border-radius: 6px;
      box-shadow: 0px 2px 4px 0px #a5a3ae4d;

      svg {
        color: #000000;
      }
    }
  }

  .p-dialog-content {
    padding: 0 20px 20px 20px;
  }
}

.change-password {
  p {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    letter-spacing: 0.01em;
    color: #122b31;
  }

  .footer {
    .p-button {
      font-weight: 500;
    }
  }
}

.p-tabview-nav {
  li {
    a {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #4b465c;

      &:hover {
        border-color: var(--torch-red);
        color: var(--torch-red);
      }
    }

    &.p-tabview-selected {
      a {
        color: var(--torch-red);
        border-color: var(--torch-red);
      }
    }
  }
}

.projects-wrap {
  border-radius: 4px;

  .password-strength-table {
    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          td {
            padding: 7px 6px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.01em;

            &:first-child {
              width: 100px;
              background-color: #959cb60d;
              border-left: 0;
              padding: 10px 15px;
            }

            span {
              display: block;
              padding: 7px 14px;
              border: 1px solid transparent;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0.01em;

              &.empty {
                color: var(--torch-red);
              }
            }
          }
        }
      }
    }
  }
}

.language-input {
  background-color: #f7fbff;
  border: 1px solid #d4d7e3;
  border-radius: 4px;
  padding: 5px 14px;

  input {
    background-color: transparent;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
  }

  .language-icons {
    gap: 10px;

    .p-button {
      width: 24px;
      height: 24px;
      padding: 0;

      span {
        padding: 0;
        display: flex;

        &::before {
          font-size: 12px;
        }
      }

      .p-button-label {
        display: none;
      }
    }
  }
}

.select-items {
  gap: 16px;

  // .select-item {
  // min-width: 168px;

  label,
  button {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: var(--black-pearl);
  }

  button {
    box-shadow: 0px 4px 18px 0px #4b465c1a;
    padding: 20px 30px;

    &:hover {
      border-color: var(--torch-red);
    }
  }
}

// }

// .p-checkbox.p-highlight .p-checkbox-box {
//   border-color: var(--torch-red);
//   background: var(--torch-red);
//   box-shadow: 0px 4px 18px 0px #4b465c1a;
// }
// .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
//   border-color: var(--torch-red);
// }

.upload-wrap h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  color: var(--black-pearl);
  font-family: var(--font-manrope);
}

.upload-wrap p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #4b465c;
}

.p-fileupload {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: var(--rhino);

  h4 {
    font-weight: 500;
  }
}

.icon-group {
  margin-top: 15px;

  button {
    margin-right: 40px;
    color: var(--black-pearl);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    .pi {
      &::before {
        color: var(--santas-gray);
      }
    }

    .p-button-label {
      color: var(--santas-gray);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

// selcet-form

.form-group {
  .p-placeholder {
    padding: 6px;
  }
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-top: 6px;
  padding-bottom: 6px;
}

.p-multiselect-filter-container .p-inputtext {
  padding-top: 6px;
  padding-bottom: 6px;
}

.p-dropdown-item-label {
  font-size: 14px;
}

.p-multiselect-items-wrapper {
  span {
    font-size: 14px;

  }
}

.p-multiselect-token-label {
  font-size: 11px;

}


.p {
  &-multiselect-panel {
    .p-multiselect {
      &-item {

        &.p {
          &-highlight {
            // background: #f123361a;
            color: red;

            .p-checkbox-box {
              background: var(--torch-red);
              border-color: var(--torch-red);
            }
          }

        }
      }
    }
  }
}